export default function useTenant() {
  const tenant = useCookie<string>('tenant', {
    secure: true,
    watch: true,
  })

  function setInitialTenant() {
    const host = useRequestURL().host
    console.log('host', host)
    const publicConfig = useRuntimeConfig().public
    const tenantsConfig = publicConfig.tenants as Record<string, string>
    const defaultTenant = publicConfig.defaultTenant as string
    const initialTenant = tenantsConfig[host] || defaultTenant
    tenant.value = initialTenant
  }

  if (!tenant.value) setInitialTenant()

  return { tenant }
}
