export function convertKeysToSnakeCase(object: any): any {
  if (object instanceof FormData) {
    console.log(`Not converting FormData -> assuming it is already snake case`)
    return object
  }
  if (Array.isArray(object)) {
    return object.map(item => convertKeysToSnakeCase(item))
  }
  if (typeof object === 'object' && object !== null) {
    return Object.entries(object).reduce((newObject, [key, value]) => {
      return { ...newObject, [useSnakeCase(key)]: convertKeysToSnakeCase(value) }
    }, {})
  }
  return object
}

export function convertKeysToCamelCase(object: any): any {
  if (Array.isArray(object)) {
    return object.map(item => convertKeysToCamelCase(item))
  }
  if (typeof object === 'object' && object !== null) {
    return Object.entries(object).reduce((newObject, [key, value]) => {
      return { ...newObject, [useCamelCase(key)]: convertKeysToCamelCase(value) }
    }, {})
  }
  return object
}
