import { defu } from 'defu'
import type { UseFetchOptions } from 'nuxt/app'
// TODO: fix type import
// import type { AsyncDataExecuteOptions } from 'nuxt/dist/app/composables/asyncData'
type AsyncDataExecuteOptions = any

const logsEnabled = false

export function getFetchOptions<T>(
  key: string | Request | Ref<string | Request> | (() => string | Request),
): UseFetchOptions<T> {
  const config = useRuntimeConfig()
  const defaultHeaders = useDefaultHeaders()
  const { authHeaders, handleAuthResponseHeaders } = useAuth()
  const { captureException, addBreadcrumb } = useSentry()

  return {
    baseURL: config.public.railsApiURL,
    // Set the cache key. This makes sure that useFetch will update the cache after a successful response.
    key: undefined,

    onRequest: ({ options }) => {
      options.headers = defu(
        defaultHeaders.value,
        options.headers,
        authHeaders.value,
      )

      // Add a breadcrump for every request
      const hasToken = !!authHeaders.value['access-token']
      addBreadcrumb({
        category: 'fetFetchOptions onRequest',
        message: `Has token: ${hasToken}`,
        level: 'info',
      })

      let snakeCaseObject = convertKeysToSnakeCase(options.body)

      // Form data should be passed directly without stringifying it
      if (!(options.body instanceof FormData)) {
        snakeCaseObject = JSON.stringify(snakeCaseObject)
      }
      options.body = snakeCaseObject
    },

    onResponse({ options, response }) {
      if (response._data) {
        response._data = convertKeysToCamelCase(response._data)
      }

      // Note: duplicate with dollarApi
      if (response.headers && !!response.headers.get('access-token')) {
        const requestHadAccessToken
          = options.headers && (options.headers as any)['access-token']

        // Commented this out to make it work with the login route.
        // I'm not really sure if this is really an issue for $api requests anyway.
        if (!requestHadAccessToken) {
          if (import.meta.env.DEV && logsEnabled) {
            console.error(
              `[useApiFetch] Received a token while the request didn't have one..`,
              requestHadAccessToken,
              options.headers,
            )
            // throw '[useApiFetch]  Received unexpected token in response.'
          }
          else {
            captureException(
              `[useApiFetch] Received unexpected token in response.`,
            )
          }
        }
        else {
          if (import.meta.env.DEV && logsEnabled) {
            console.log(
              `[useApiFetch] Received headers for response`,
              response,
              options,
            )
          }
          handleAuthResponseHeaders(response.headers)
        }
      }
    },

    // onRequestError(_ctx) {
    //   console.error(`Request error`, _ctx)
    // },

    onResponseError({ response }) {
      const error = response._data.error
      if (error && error.includes('wrong center')) {
        // console.log(`❌ WRONG CENTER`)
        // Throw a specific error so that it is captured by Sentry, and we're able to show a user friendly page
        throw new Error(error)
      }
    },
  }
}

// Extract attributes or array of attributes directly. This makes it possible to do
// `const { attributes: user } = useApiFetch<User>('/user-url')` or
// `const { attributes: users } = useApiFetch<Users>('/users-url')`.
// Type T is the type of the attributes.
// Type U is for the includeded relationships.
//
// TODO: in order to make this work with $api we have make it work with an input
// variable (currently fetchResponse) this is not a ref, and which has non-ref
// data properties.
function extractAttributes<T, U = Record<string, JSONAPIResource<any>> | null>(
  // TODO: fix this type
  fetchResponse: any, // Ref<ApiResponse<T>>,
) {
  // Extract the instance attributes
  const attributes = computed<T>(() => {
    const data = fetchResponse.data
    if (data.value === null) return null
    // console.log(`fetchResponse`, data.value)
    if (Array.isArray(data.value?.data)) {
      return data.value.data.map((item: any) => item.attributes)
    }
    return data.value?.data.attributes
  })

  // Also extract relationships
  // Something with the typing is wrong here. See the desks and conversations clients for the consequences of this.
  const relationships = computed<U>(() => {
    const resource = fetchResponse.data.value?.data
    const included = fetchResponse.data.value?.included

    if (!included || !resource) return null

    // A map of included resources, where the key is in the format `type-{id}`,
    // for example: 'activity_type-5998` or `audience-1`.
    // And the value is the attributes object of the resource.
    const includedMap: Record<string, JSONAPIResource<any>> = {}
    // Map the included resources for easy access
    included.forEach((resource: JSONAPIResource<any>) => {
      includedMap[`${resource.type}-${resource.id}`] = resource.attributes
    })

    // resource can be a single resource or an array of resources
    if (Array.isArray(resource)) {
      return resource.map((resource: JSONAPIResource<any>) =>
        mapRelationships<T, U>(resource, includedMap),
      ) as any
    }
    return mapRelationships<T, U>(resource, includedMap)
  })

  return { attributes, relationships }
}

// Looks at the relationships defined on resource and maps them to the included resources.
// Returns a type Record<string, JSONAPIResource<U, {}>>
function mapRelationships<T, U>(
  resource: JSONAPIResource<T>,
  includedMap: Record<string, JSONAPIResource<U>>,
) {
  const resourceRels: Record<
    string,
    JSONAPIResource<U> | JSONAPIResource<any>[]
  > = {}

  for (const relKey in resource.relationships) {
    // TODO: fix types
    const relationData = resource.relationships[relKey].data

    if (!relationData) {
      // When the relation is nullable, this can be null. For example: vacancy with an optional address.
    }
    else if (Array.isArray(relationData)) {
      resourceRels[relKey] = relationData.map(
        rel => includedMap[`${rel.type}-${rel.id}`],
      )
    }
    else {
      resourceRels[relKey]
        = includedMap[`${relationData.type}-${relationData.id}`]
    }
  }

  return resourceRels
}

// The return type of useApiFetch and useLazyApiFetch.
// This is a combination of the return type of useFetch and our own custom attributes of type JSONAPIAttributeType.
type ApiFetchReturn<T, U = null> = {
  // From useFetch:
  data: Ref<JSONAPIResponse<T, U> | null>
  pending: Ref<boolean>
  refresh: (opts?: AsyncDataExecuteOptions) => Promise<void>
  execute: (opts?: AsyncDataExecuteOptions) => Promise<void>
  error: Ref<Error | null> // TODO: use ErrorT from useFetch?
  // Added custom:
  attributes: globalThis.ComputedRef<T>
  relationships: globalThis.ComputedRef<U>
}

// Non-lazy version. Awaits the fetch before it returns.
// Note that this function doesn't return a `pending` property, because it's not lazy/async.
// It does return an error property, but it will only be used if `throwOnError` is false.
export async function useApiFetch<T, U = null>(
  url: string | Request | Ref<string | Request> | (() => string | Request),
  options: UseFetchOptions<JSONAPIResponse<T, U>> = {},
  // By default, useFetch doesn't throw errors. We however, being lazy developers, throw errors when they occur.
  // This way, we don't have to check for errors in every single client or component.
  //
  // Eh...Why did I do this? This basically means I'm doing what $api does, but with useFetch. Might as will switch to $api?
  throwOnError = true,
): Promise<ApiFetchReturn<T, U>> {
  if (options.lazy) {
    console.warn(
      `⚠️  useApiFetch does not support lazy mode. Use useLazyApiFetch instead.`,
      `Nuxt's own useFetch does supports this flag, but the custom wrapper doesn't implement it (yet).`,
    )
  }

  const defaultOptions = getFetchOptions<JSONAPIResponse<T, U>>(url)

  // Use unjs/defu for nice deep defaults
  const mergedOptions = defu({}, options, defaultOptions)

  const fetchResponse = await useFetch<JSONAPIResponse<T, U>>(url as string, {
    ...mergedOptions,
    // getCachedData: tryCachedResponse,
  })

  const { attributes, relationships } = extractAttributes<T, U>(fetchResponse)

  if (throwOnError && fetchResponse.error.value) {
    throw fetchResponse.error.value
  }

  return { ...fetchResponse, attributes, relationships }
}

// Lazy version of useApiFetch. This doesn't await the fetch, but returns a promise instead.
export function useLazyApiFetch<T, U = null>(
  url: string,
  options: UseFetchOptions<JSONAPIResponse<T, U>> = {},
  // See comments in useApiFetch
  throwOnError = true,
): ApiFetchReturn<T, U> {
  const defaultOptions = getFetchOptions<JSONAPIResponse<T, U>>(url)

  // Use unjs/defu for nice deep defaults
  const mergedOptions = defu({}, options, defaultOptions)

  const fetchResponse = useLazyFetch<JSONAPIResponse<T, U>>(url, {
    ...mergedOptions,
    // getCachedData: tryCachedResponse,
  })

  const { attributes, relationships } = extractAttributes<T, U>(fetchResponse)

  if (throwOnError && fetchResponse.error.value) {
    throw fetchResponse.error.value
  }

  return { ...fetchResponse, attributes, relationships }
}
