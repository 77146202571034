import { default as __indexIUfKi05x4WMeta } from "/vercel/path0/layers/tairo/pages/__index.vue?macro=true";
import { default as _91_91slug_93_934wkjTaWaIPMeta } from "/vercel/path0/stagedoos/pages/[[slug]].vue?macro=true";
import { default as index_4528SAiEe01y8Meta } from "/vercel/path0/.app/pages/analytics/index-2.vue?macro=true";
import { default as indexTS7YDSsDxsMeta } from "/vercel/path0/.app/pages/analytics/index.vue?macro=true";
import { default as login_451PzktOBWuMrMeta } from "/vercel/path0/.app/pages/auth/login-1.vue?macro=true";
import { default as recoverDHSNCySTpTMeta } from "/vercel/path0/.app/pages/auth/recover.vue?macro=true";
import { default as reset389BRdnuMwMeta } from "/vercel/path0/.app/pages/auth/reset.vue?macro=true";
import { default as token5YDpd8Z7VVMeta } from "/vercel/path0/.app/pages/auth/token.vue?macro=true";
import { default as contentTavsgksC19Meta } from "/vercel/path0/.app/pages/content.vue?macro=true";
import { default as editBOMAhLSPhYMeta } from "/vercel/path0/stagedoos/pages/contract_templates/[id]/edit.vue?macro=true";
import { default as indexO6jJumkuhIMeta } from "/vercel/path0/stagedoos/pages/contract_templates/index.vue?macro=true";
import { default as contractHyCr6WZNxrMeta } from "/vercel/path0/stagedoos/pages/contract.vue?macro=true";
import { default as indexbPRpJanaU4Meta } from "/vercel/path0/stagedoos/pages/contracts/[id]/index.vue?macro=true";
import { default as _91signer_id_93drMzfOSK0dMeta } from "/vercel/path0/stagedoos/pages/contracts/[id]/sign/[signer_id].vue?macro=true";
import { default as conversationsPeTz0Z7yCtMeta } from "/vercel/path0/stagedoos/pages/conversations.vue?macro=true";
import { default as dashboardBOcXGg8wRkMeta } from "/vercel/path0/stagedoos/pages/dashboard.vue?macro=true";
import { default as editgnJzgPpaRVMeta } from "/vercel/path0/stagedoos/pages/groups/[id]/edit.vue?macro=true";
import { default as index9S2ktcAHaBMeta } from "/vercel/path0/stagedoos/pages/groups/[id]/index.vue?macro=true";
import { default as addfsIi597JBGMeta } from "/vercel/path0/stagedoos/pages/groups/add.vue?macro=true";
import { default as indexJolRkOXECQMeta } from "/vercel/path0/stagedoos/pages/groups/index.vue?macro=true";
import { default as indexiGdjxCtcJKMeta } from "/vercel/path0/stagedoos/pages/index.vue?macro=true";
import { default as inloggen33i0JW8OPjMeta } from "/vercel/path0/stagedoos/pages/inloggen.vue?macro=true";
import { default as indexSoCpeoUEVMMeta } from "/vercel/path0/stagedoos/pages/instellingen/index.vue?macro=true";
import { default as editRtxKbV3vADMeta } from "/vercel/path0/stagedoos/pages/internship_periods/[id]/edit.vue?macro=true";
import { default as add03Bonw2dwMMeta } from "/vercel/path0/stagedoos/pages/internship_periods/add.vue?macro=true";
import { default as index4oyC9aVbVSMeta } from "/vercel/path0/stagedoos/pages/internship_periods/index.vue?macro=true";
import { default as editY20Sw3nn1kMeta } from "/vercel/path0/stagedoos/pages/internships/[id]/edit.vue?macro=true";
import { default as indexmrRsfE0YInMeta } from "/vercel/path0/stagedoos/pages/internships/[id]/index.vue?macro=true";
import { default as assignAtl0GT49DfMeta } from "/vercel/path0/stagedoos/pages/internships/assign.vue?macro=true";
import { default as indexhUtC7smSrFMeta } from "/vercel/path0/stagedoos/pages/internships/index.vue?macro=true";
import { default as adda6awO3cvmzMeta } from "/vercel/path0/stagedoos/pages/mentorships/add.vue?macro=true";
import { default as indexxywXcPxppNMeta } from "/vercel/path0/stagedoos/pages/mentorships/index.vue?macro=true";
import { default as indexlC3c1MPmmIMeta } from "/vercel/path0/stagedoos/pages/notificatie_templates/index.vue?macro=true";
import { default as indexwk9YNLKAguMeta } from "/vercel/path0/.app/pages/notificaties/index.vue?macro=true";
import { default as editfvbLSXHjQLMeta } from "/vercel/path0/stagedoos/pages/organizations/[id]/edit.vue?macro=true";
import { default as indexcMpEBUKd7KMeta } from "/vercel/path0/stagedoos/pages/organizations/[id]/index.vue?macro=true";
import { default as addycMCuVYsuvMeta } from "/vercel/path0/stagedoos/pages/organizations/add.vue?macro=true";
import { default as approvehEM7f8ipaoMeta } from "/vercel/path0/stagedoos/pages/organizations/approve.vue?macro=true";
import { default as indexRekqGZqDU5Meta } from "/vercel/path0/stagedoos/pages/organizations/index.vue?macro=true";
import { default as indexupW52PgBHoMeta } from "/vercel/path0/stagedoos/pages/organizations/signup/index.vue?macro=true";
import { default as successPZNER546NdMeta } from "/vercel/path0/stagedoos/pages/organizations/signup/success.vue?macro=true";
import { default as profile_45edit0oMFymvf4iMeta } from "/vercel/path0/.app/pages/profile-edit.vue?macro=true";
import { default as recente_acties4JTw8OyHl6Meta } from "/vercel/path0/stagedoos/pages/recente_acties.vue?macro=true";
import { default as indexTu2yZLPwe0Meta } from "/vercel/path0/stagedoos/pages/stagebank/index.vue?macro=true";
import { default as index9f7GSYX76uMeta } from "/vercel/path0/stagedoos/pages/stagevoortgang/index.vue?macro=true";
import { default as uitlegguLVBPC5C3Meta } from "/vercel/path0/stagedoos/pages/stagevoortgang/uitleg.vue?macro=true";
import { default as assign_groupjBEpRtVxOFMeta } from "/vercel/path0/stagedoos/pages/students/[id]/assign_group.vue?macro=true";
import { default as authkaSMnQT4WcMeta } from "/vercel/path0/.app/pages/test/auth.vue?macro=true";
import { default as datepicker2DaAkr5UulMeta } from "/vercel/path0/.app/pages/test/datepicker.vue?macro=true";
import { default as dupeI47ZtOXqSqMeta } from "/vercel/path0/.app/pages/test/dupe.vue?macro=true";
import { default as formkm7kcPhdf0Meta } from "/vercel/path0/.app/pages/test/form.vue?macro=true";
import { default as server_45errorzkZTzpIMvfMeta } from "/vercel/path0/.app/pages/test/server-error.vue?macro=true";
import { default as stagedoos_fieldspb2rEOny0PMeta } from "/vercel/path0/stagedoos/pages/test/stagedoos_fields.vue?macro=true";
import { default as step_45form_452zOUvlFOLgwMeta } from "/vercel/path0/.app/pages/test/step-form-2.vue?macro=true";
import { default as step_45formbVuEsOyEdOMeta } from "/vercel/path0/.app/pages/test/step-form.vue?macro=true";
import { default as toasterdGo68mWjbTMeta } from "/vercel/path0/.app/pages/test/toaster.vue?macro=true";
import { default as user_45storeNZaKEdPvEJMeta } from "/vercel/path0/.app/pages/test/user-store.vue?macro=true";
import { default as usermbs7HBNeeAMeta } from "/vercel/path0/.app/pages/test/user.vue?macro=true";
import { default as uitloggenJpGYx4npziMeta } from "/vercel/path0/.app/pages/uitloggen.vue?macro=true";
import { default as _91id_93ZbnqYzJMoNMeta } from "/vercel/path0/stagedoos/pages/urenbriefje/[id].vue?macro=true";
import { default as add_role1rGOgsOd7sMeta } from "/vercel/path0/.app/pages/users/[id]/add_role.vue?macro=true";
import { default as edit2D4CWW5dzWMeta } from "/vercel/path0/.app/pages/users/[id]/edit.vue?macro=true";
import { default as indexfosjwbxWtaMeta } from "/vercel/path0/.app/pages/users/[id]/index.vue?macro=true";
import { default as adds9wtR2FJuZMeta } from "/vercel/path0/.app/pages/users/add.vue?macro=true";
import { default as indexdlFaUjo5BDMeta } from "/vercel/path0/.app/pages/users/index.vue?macro=true";
import { default as edito5crPmYslWMeta } from "/vercel/path0/stagedoos/pages/vacancies/[id]/edit.vue?macro=true";
import { default as indexQASgYGPynxMeta } from "/vercel/path0/stagedoos/pages/vacancies/[id]/index.vue?macro=true";
import { default as addqQCfItJQmNMeta } from "/vercel/path0/stagedoos/pages/vacancies/add.vue?macro=true";
import { default as indexWZnTPYQ2NQMeta } from "/vercel/path0/stagedoos/pages/vacancies/index.vue?macro=true";
import { default as indexHq2dcm1jMAMeta } from "/vercel/path0/stagedoos/pages/workflows/[id]/[phase_id]/index.vue?macro=true";
import { default as indexNXogjRzmdTMeta } from "/vercel/path0/stagedoos/pages/workflows/[id]/index.vue?macro=true";
import { default as indexAr3FAPgfCeMeta } from "/vercel/path0/stagedoos/pages/workflows/index.vue?macro=true";
import { default as zelfgevondenVLq5PsPnEwMeta } from "/vercel/path0/stagedoos/pages/zelfgevonden.vue?macro=true";
export default [
  {
    name: __indexIUfKi05x4WMeta?.name ?? "__index",
    path: __indexIUfKi05x4WMeta?.path ?? "/__index",
    meta: __indexIUfKi05x4WMeta || {},
    alias: __indexIUfKi05x4WMeta?.alias || [],
    redirect: __indexIUfKi05x4WMeta?.redirect,
    component: () => import("/vercel/path0/layers/tairo/pages/__index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934wkjTaWaIPMeta?.name ?? "slug",
    path: _91_91slug_93_934wkjTaWaIPMeta?.path ?? "/:slug?",
    meta: _91_91slug_93_934wkjTaWaIPMeta || {},
    alias: _91_91slug_93_934wkjTaWaIPMeta?.alias || [],
    redirect: _91_91slug_93_934wkjTaWaIPMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: index_4528SAiEe01y8Meta?.name ?? "analytics-index-2",
    path: index_4528SAiEe01y8Meta?.path ?? "/analytics/index-2",
    meta: index_4528SAiEe01y8Meta || {},
    alias: index_4528SAiEe01y8Meta?.alias || [],
    redirect: index_4528SAiEe01y8Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/analytics/index-2.vue").then(m => m.default || m)
  },
  {
    name: indexTS7YDSsDxsMeta?.name ?? "analytics",
    path: indexTS7YDSsDxsMeta?.path ?? "/analytics",
    meta: indexTS7YDSsDxsMeta || {},
    alias: indexTS7YDSsDxsMeta?.alias || [],
    redirect: indexTS7YDSsDxsMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: login_451PzktOBWuMrMeta?.name ?? "auth-login-1",
    path: login_451PzktOBWuMrMeta?.path ?? "/auth/login-1",
    meta: login_451PzktOBWuMrMeta || {},
    alias: login_451PzktOBWuMrMeta?.alias || [],
    redirect: login_451PzktOBWuMrMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/login-1.vue").then(m => m.default || m)
  },
  {
    name: recoverDHSNCySTpTMeta?.name ?? "auth-recover",
    path: recoverDHSNCySTpTMeta?.path ?? "/auth/recover",
    meta: recoverDHSNCySTpTMeta || {},
    alias: recoverDHSNCySTpTMeta?.alias || [],
    redirect: recoverDHSNCySTpTMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/recover.vue").then(m => m.default || m)
  },
  {
    name: reset389BRdnuMwMeta?.name ?? "auth-reset",
    path: reset389BRdnuMwMeta?.path ?? "/auth/reset",
    meta: reset389BRdnuMwMeta || {},
    alias: reset389BRdnuMwMeta?.alias || [],
    redirect: reset389BRdnuMwMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/reset.vue").then(m => m.default || m)
  },
  {
    name: token5YDpd8Z7VVMeta?.name ?? "auth-token",
    path: token5YDpd8Z7VVMeta?.path ?? "/auth/token",
    meta: token5YDpd8Z7VVMeta || {},
    alias: token5YDpd8Z7VVMeta?.alias || [],
    redirect: token5YDpd8Z7VVMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/auth/token.vue").then(m => m.default || m)
  },
  {
    name: contentTavsgksC19Meta?.name ?? "content",
    path: contentTavsgksC19Meta?.path ?? "/content",
    meta: contentTavsgksC19Meta || {},
    alias: contentTavsgksC19Meta?.alias || [],
    redirect: contentTavsgksC19Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/content.vue").then(m => m.default || m)
  },
  {
    name: editBOMAhLSPhYMeta?.name ?? "contract_templates-id-edit",
    path: editBOMAhLSPhYMeta?.path ?? "/contract_templates/:id()/edit",
    meta: editBOMAhLSPhYMeta || {},
    alias: editBOMAhLSPhYMeta?.alias || [],
    redirect: editBOMAhLSPhYMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/contract_templates/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexO6jJumkuhIMeta?.name ?? "contract_templates",
    path: indexO6jJumkuhIMeta?.path ?? "/contract_templates",
    meta: indexO6jJumkuhIMeta || {},
    alias: indexO6jJumkuhIMeta?.alias || [],
    redirect: indexO6jJumkuhIMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/contract_templates/index.vue").then(m => m.default || m)
  },
  {
    name: contractHyCr6WZNxrMeta?.name ?? "contract",
    path: contractHyCr6WZNxrMeta?.path ?? "/contract",
    meta: contractHyCr6WZNxrMeta || {},
    alias: contractHyCr6WZNxrMeta?.alias || [],
    redirect: contractHyCr6WZNxrMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/contract.vue").then(m => m.default || m)
  },
  {
    name: indexbPRpJanaU4Meta?.name ?? "contracts-id",
    path: indexbPRpJanaU4Meta?.path ?? "/contracts/:id()",
    meta: indexbPRpJanaU4Meta || {},
    alias: indexbPRpJanaU4Meta?.alias || [],
    redirect: indexbPRpJanaU4Meta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/contracts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91signer_id_93drMzfOSK0dMeta?.name ?? "contracts-id-sign-signer_id",
    path: _91signer_id_93drMzfOSK0dMeta?.path ?? "/contracts/:id()/sign/:signer_id()",
    meta: _91signer_id_93drMzfOSK0dMeta || {},
    alias: _91signer_id_93drMzfOSK0dMeta?.alias || [],
    redirect: _91signer_id_93drMzfOSK0dMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/contracts/[id]/sign/[signer_id].vue").then(m => m.default || m)
  },
  {
    name: conversationsPeTz0Z7yCtMeta?.name ?? "conversations",
    path: conversationsPeTz0Z7yCtMeta?.path ?? "/conversations",
    meta: conversationsPeTz0Z7yCtMeta || {},
    alias: conversationsPeTz0Z7yCtMeta?.alias || [],
    redirect: conversationsPeTz0Z7yCtMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/conversations.vue").then(m => m.default || m)
  },
  {
    name: dashboardBOcXGg8wRkMeta?.name ?? "dashboard",
    path: dashboardBOcXGg8wRkMeta?.path ?? "/dashboard",
    meta: dashboardBOcXGg8wRkMeta || {},
    alias: dashboardBOcXGg8wRkMeta?.alias || [],
    redirect: dashboardBOcXGg8wRkMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: editgnJzgPpaRVMeta?.name ?? "groups-id-edit",
    path: editgnJzgPpaRVMeta?.path ?? "/groups/:id()/edit",
    meta: editgnJzgPpaRVMeta || {},
    alias: editgnJzgPpaRVMeta?.alias || [],
    redirect: editgnJzgPpaRVMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/groups/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9S2ktcAHaBMeta?.name ?? "groups-id",
    path: index9S2ktcAHaBMeta?.path ?? "/groups/:id()",
    meta: index9S2ktcAHaBMeta || {},
    alias: index9S2ktcAHaBMeta?.alias || [],
    redirect: index9S2ktcAHaBMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/groups/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addfsIi597JBGMeta?.name ?? "groups-add",
    path: addfsIi597JBGMeta?.path ?? "/groups/add",
    meta: addfsIi597JBGMeta || {},
    alias: addfsIi597JBGMeta?.alias || [],
    redirect: addfsIi597JBGMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/groups/add.vue").then(m => m.default || m)
  },
  {
    name: indexJolRkOXECQMeta?.name ?? "groups",
    path: indexJolRkOXECQMeta?.path ?? "/groups",
    meta: indexJolRkOXECQMeta || {},
    alias: indexJolRkOXECQMeta?.alias || [],
    redirect: indexJolRkOXECQMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexiGdjxCtcJKMeta?.name ?? "index",
    path: indexiGdjxCtcJKMeta?.path ?? "/",
    meta: indexiGdjxCtcJKMeta || {},
    alias: indexiGdjxCtcJKMeta?.alias || [],
    redirect: indexiGdjxCtcJKMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inloggen33i0JW8OPjMeta?.name ?? "inloggen",
    path: inloggen33i0JW8OPjMeta?.path ?? "/inloggen",
    meta: inloggen33i0JW8OPjMeta || {},
    alias: inloggen33i0JW8OPjMeta?.alias || [],
    redirect: inloggen33i0JW8OPjMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: indexSoCpeoUEVMMeta?.name ?? "instellingen",
    path: indexSoCpeoUEVMMeta?.path ?? "/instellingen",
    meta: indexSoCpeoUEVMMeta || {},
    alias: indexSoCpeoUEVMMeta?.alias || [],
    redirect: indexSoCpeoUEVMMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/instellingen/index.vue").then(m => m.default || m)
  },
  {
    name: editRtxKbV3vADMeta?.name ?? "internship_periods-id-edit",
    path: editRtxKbV3vADMeta?.path ?? "/internship_periods/:id()/edit",
    meta: editRtxKbV3vADMeta || {},
    alias: editRtxKbV3vADMeta?.alias || [],
    redirect: editRtxKbV3vADMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: add03Bonw2dwMMeta?.name ?? "internship_periods-add",
    path: add03Bonw2dwMMeta?.path ?? "/internship_periods/add",
    meta: add03Bonw2dwMMeta || {},
    alias: add03Bonw2dwMMeta?.alias || [],
    redirect: add03Bonw2dwMMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/add.vue").then(m => m.default || m)
  },
  {
    name: index4oyC9aVbVSMeta?.name ?? "internship_periods",
    path: index4oyC9aVbVSMeta?.path ?? "/internship_periods",
    meta: index4oyC9aVbVSMeta || {},
    alias: index4oyC9aVbVSMeta?.alias || [],
    redirect: index4oyC9aVbVSMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internship_periods/index.vue").then(m => m.default || m)
  },
  {
    name: editY20Sw3nn1kMeta?.name ?? "internships-id-edit",
    path: editY20Sw3nn1kMeta?.path ?? "/internships/:id()/edit",
    meta: editY20Sw3nn1kMeta || {},
    alias: editY20Sw3nn1kMeta?.alias || [],
    redirect: editY20Sw3nn1kMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internships/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexmrRsfE0YInMeta?.name ?? "internships-id",
    path: indexmrRsfE0YInMeta?.path ?? "/internships/:id()",
    meta: indexmrRsfE0YInMeta || {},
    alias: indexmrRsfE0YInMeta?.alias || [],
    redirect: indexmrRsfE0YInMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internships/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: assignAtl0GT49DfMeta?.name ?? "internships-assign",
    path: assignAtl0GT49DfMeta?.path ?? "/internships/assign",
    meta: assignAtl0GT49DfMeta || {},
    alias: assignAtl0GT49DfMeta?.alias || [],
    redirect: assignAtl0GT49DfMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internships/assign.vue").then(m => m.default || m)
  },
  {
    name: indexhUtC7smSrFMeta?.name ?? "internships",
    path: indexhUtC7smSrFMeta?.path ?? "/internships",
    meta: indexhUtC7smSrFMeta || {},
    alias: indexhUtC7smSrFMeta?.alias || [],
    redirect: indexhUtC7smSrFMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/internships/index.vue").then(m => m.default || m)
  },
  {
    name: adda6awO3cvmzMeta?.name ?? "mentorships-add",
    path: adda6awO3cvmzMeta?.path ?? "/mentorships/add",
    meta: adda6awO3cvmzMeta || {},
    alias: adda6awO3cvmzMeta?.alias || [],
    redirect: adda6awO3cvmzMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/mentorships/add.vue").then(m => m.default || m)
  },
  {
    name: indexxywXcPxppNMeta?.name ?? "mentorships",
    path: indexxywXcPxppNMeta?.path ?? "/mentorships",
    meta: indexxywXcPxppNMeta || {},
    alias: indexxywXcPxppNMeta?.alias || [],
    redirect: indexxywXcPxppNMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/mentorships/index.vue").then(m => m.default || m)
  },
  {
    name: indexlC3c1MPmmIMeta?.name ?? "notificatie_templates",
    path: indexlC3c1MPmmIMeta?.path ?? "/notificatie_templates",
    meta: indexlC3c1MPmmIMeta || {},
    alias: indexlC3c1MPmmIMeta?.alias || [],
    redirect: indexlC3c1MPmmIMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/notificatie_templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexwk9YNLKAguMeta?.name ?? "notificaties",
    path: indexwk9YNLKAguMeta?.path ?? "/notificaties",
    meta: indexwk9YNLKAguMeta || {},
    alias: indexwk9YNLKAguMeta?.alias || [],
    redirect: indexwk9YNLKAguMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/notificaties/index.vue").then(m => m.default || m)
  },
  {
    name: editfvbLSXHjQLMeta?.name ?? "organizations-id-edit",
    path: editfvbLSXHjQLMeta?.path ?? "/organizations/:id()/edit",
    meta: editfvbLSXHjQLMeta || {},
    alias: editfvbLSXHjQLMeta?.alias || [],
    redirect: editfvbLSXHjQLMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexcMpEBUKd7KMeta?.name ?? "organizations-id",
    path: indexcMpEBUKd7KMeta?.path ?? "/organizations/:id()",
    meta: indexcMpEBUKd7KMeta || {},
    alias: indexcMpEBUKd7KMeta?.alias || [],
    redirect: indexcMpEBUKd7KMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addycMCuVYsuvMeta?.name ?? "organizations-add",
    path: addycMCuVYsuvMeta?.path ?? "/organizations/add",
    meta: addycMCuVYsuvMeta || {},
    alias: addycMCuVYsuvMeta?.alias || [],
    redirect: addycMCuVYsuvMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/add.vue").then(m => m.default || m)
  },
  {
    name: approvehEM7f8ipaoMeta?.name ?? "organizations-approve",
    path: approvehEM7f8ipaoMeta?.path ?? "/organizations/approve",
    meta: approvehEM7f8ipaoMeta || {},
    alias: approvehEM7f8ipaoMeta?.alias || [],
    redirect: approvehEM7f8ipaoMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/approve.vue").then(m => m.default || m)
  },
  {
    name: indexRekqGZqDU5Meta?.name ?? "organizations",
    path: indexRekqGZqDU5Meta?.path ?? "/organizations",
    meta: indexRekqGZqDU5Meta || {},
    alias: indexRekqGZqDU5Meta?.alias || [],
    redirect: indexRekqGZqDU5Meta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: indexupW52PgBHoMeta?.name ?? "organizations-signup",
    path: indexupW52PgBHoMeta?.path ?? "/organizations/signup",
    meta: indexupW52PgBHoMeta || {},
    alias: indexupW52PgBHoMeta?.alias || [],
    redirect: indexupW52PgBHoMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/signup/index.vue").then(m => m.default || m)
  },
  {
    name: successPZNER546NdMeta?.name ?? "organizations-signup-success",
    path: successPZNER546NdMeta?.path ?? "/organizations/signup/success",
    meta: successPZNER546NdMeta || {},
    alias: successPZNER546NdMeta?.alias || [],
    redirect: successPZNER546NdMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/organizations/signup/success.vue").then(m => m.default || m)
  },
  {
    name: profile_45edit0oMFymvf4iMeta?.name ?? "profile-edit",
    path: profile_45edit0oMFymvf4iMeta?.path ?? "/profile-edit",
    meta: profile_45edit0oMFymvf4iMeta || {},
    alias: profile_45edit0oMFymvf4iMeta?.alias || [],
    redirect: profile_45edit0oMFymvf4iMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/profile-edit.vue").then(m => m.default || m)
  },
  {
    name: recente_acties4JTw8OyHl6Meta?.name ?? "recente_acties",
    path: recente_acties4JTw8OyHl6Meta?.path ?? "/recente_acties",
    meta: recente_acties4JTw8OyHl6Meta || {},
    alias: recente_acties4JTw8OyHl6Meta?.alias || [],
    redirect: recente_acties4JTw8OyHl6Meta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/recente_acties.vue").then(m => m.default || m)
  },
  {
    name: indexTu2yZLPwe0Meta?.name ?? "stagebank",
    path: indexTu2yZLPwe0Meta?.path ?? "/stagebank",
    meta: indexTu2yZLPwe0Meta || {},
    alias: indexTu2yZLPwe0Meta?.alias || [],
    redirect: indexTu2yZLPwe0Meta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/stagebank/index.vue").then(m => m.default || m)
  },
  {
    name: index9f7GSYX76uMeta?.name ?? "stagevoortgang",
    path: index9f7GSYX76uMeta?.path ?? "/stagevoortgang",
    meta: index9f7GSYX76uMeta || {},
    alias: index9f7GSYX76uMeta?.alias || [],
    redirect: index9f7GSYX76uMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/stagevoortgang/index.vue").then(m => m.default || m)
  },
  {
    name: uitlegguLVBPC5C3Meta?.name ?? "stagevoortgang-uitleg",
    path: uitlegguLVBPC5C3Meta?.path ?? "/stagevoortgang/uitleg",
    meta: uitlegguLVBPC5C3Meta || {},
    alias: uitlegguLVBPC5C3Meta?.alias || [],
    redirect: uitlegguLVBPC5C3Meta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/stagevoortgang/uitleg.vue").then(m => m.default || m)
  },
  {
    name: assign_groupjBEpRtVxOFMeta?.name ?? "students-id-assign_group",
    path: assign_groupjBEpRtVxOFMeta?.path ?? "/students/:id()/assign_group",
    meta: assign_groupjBEpRtVxOFMeta || {},
    alias: assign_groupjBEpRtVxOFMeta?.alias || [],
    redirect: assign_groupjBEpRtVxOFMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/students/[id]/assign_group.vue").then(m => m.default || m)
  },
  {
    name: authkaSMnQT4WcMeta?.name ?? "test-auth",
    path: authkaSMnQT4WcMeta?.path ?? "/test/auth",
    meta: authkaSMnQT4WcMeta || {},
    alias: authkaSMnQT4WcMeta?.alias || [],
    redirect: authkaSMnQT4WcMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/auth.vue").then(m => m.default || m)
  },
  {
    name: datepicker2DaAkr5UulMeta?.name ?? "test-datepicker",
    path: datepicker2DaAkr5UulMeta?.path ?? "/test/datepicker",
    meta: datepicker2DaAkr5UulMeta || {},
    alias: datepicker2DaAkr5UulMeta?.alias || [],
    redirect: datepicker2DaAkr5UulMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/datepicker.vue").then(m => m.default || m)
  },
  {
    name: dupeI47ZtOXqSqMeta?.name ?? "test-dupe",
    path: dupeI47ZtOXqSqMeta?.path ?? "/test/dupe",
    meta: dupeI47ZtOXqSqMeta || {},
    alias: dupeI47ZtOXqSqMeta?.alias || [],
    redirect: dupeI47ZtOXqSqMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/dupe.vue").then(m => m.default || m)
  },
  {
    name: formkm7kcPhdf0Meta?.name ?? "test-form",
    path: formkm7kcPhdf0Meta?.path ?? "/test/form",
    meta: formkm7kcPhdf0Meta || {},
    alias: formkm7kcPhdf0Meta?.alias || [],
    redirect: formkm7kcPhdf0Meta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/form.vue").then(m => m.default || m)
  },
  {
    name: server_45errorzkZTzpIMvfMeta?.name ?? "test-server-error",
    path: server_45errorzkZTzpIMvfMeta?.path ?? "/test/server-error",
    meta: server_45errorzkZTzpIMvfMeta || {},
    alias: server_45errorzkZTzpIMvfMeta?.alias || [],
    redirect: server_45errorzkZTzpIMvfMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/server-error.vue").then(m => m.default || m)
  },
  {
    name: stagedoos_fieldspb2rEOny0PMeta?.name ?? "test-stagedoos_fields",
    path: stagedoos_fieldspb2rEOny0PMeta?.path ?? "/test/stagedoos_fields",
    meta: stagedoos_fieldspb2rEOny0PMeta || {},
    alias: stagedoos_fieldspb2rEOny0PMeta?.alias || [],
    redirect: stagedoos_fieldspb2rEOny0PMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/test/stagedoos_fields.vue").then(m => m.default || m)
  },
  {
    name: step_45form_452zOUvlFOLgwMeta?.name ?? "test-step-form-2",
    path: step_45form_452zOUvlFOLgwMeta?.path ?? "/test/step-form-2",
    meta: step_45form_452zOUvlFOLgwMeta || {},
    alias: step_45form_452zOUvlFOLgwMeta?.alias || [],
    redirect: step_45form_452zOUvlFOLgwMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/step-form-2.vue").then(m => m.default || m)
  },
  {
    name: step_45formbVuEsOyEdOMeta?.name ?? "test-step-form",
    path: step_45formbVuEsOyEdOMeta?.path ?? "/test/step-form",
    meta: step_45formbVuEsOyEdOMeta || {},
    alias: step_45formbVuEsOyEdOMeta?.alias || [],
    redirect: step_45formbVuEsOyEdOMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/step-form.vue").then(m => m.default || m)
  },
  {
    name: toasterdGo68mWjbTMeta?.name ?? "test-toaster",
    path: toasterdGo68mWjbTMeta?.path ?? "/test/toaster",
    meta: toasterdGo68mWjbTMeta || {},
    alias: toasterdGo68mWjbTMeta?.alias || [],
    redirect: toasterdGo68mWjbTMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/toaster.vue").then(m => m.default || m)
  },
  {
    name: user_45storeNZaKEdPvEJMeta?.name ?? "test-user-store",
    path: user_45storeNZaKEdPvEJMeta?.path ?? "/test/user-store",
    meta: user_45storeNZaKEdPvEJMeta || {},
    alias: user_45storeNZaKEdPvEJMeta?.alias || [],
    redirect: user_45storeNZaKEdPvEJMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/user-store.vue").then(m => m.default || m)
  },
  {
    name: usermbs7HBNeeAMeta?.name ?? "test-user",
    path: usermbs7HBNeeAMeta?.path ?? "/test/user",
    meta: usermbs7HBNeeAMeta || {},
    alias: usermbs7HBNeeAMeta?.alias || [],
    redirect: usermbs7HBNeeAMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/test/user.vue").then(m => m.default || m)
  },
  {
    name: uitloggenJpGYx4npziMeta?.name ?? "uitloggen",
    path: uitloggenJpGYx4npziMeta?.path ?? "/uitloggen",
    meta: uitloggenJpGYx4npziMeta || {},
    alias: uitloggenJpGYx4npziMeta?.alias || [],
    redirect: uitloggenJpGYx4npziMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/uitloggen.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZbnqYzJMoNMeta?.name ?? "urenbriefje-id",
    path: _91id_93ZbnqYzJMoNMeta?.path ?? "/urenbriefje/:id()",
    meta: _91id_93ZbnqYzJMoNMeta || {},
    alias: _91id_93ZbnqYzJMoNMeta?.alias || [],
    redirect: _91id_93ZbnqYzJMoNMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/urenbriefje/[id].vue").then(m => m.default || m)
  },
  {
    name: add_role1rGOgsOd7sMeta?.name ?? "users-id-add_role",
    path: add_role1rGOgsOd7sMeta?.path ?? "/users/:id()/add_role",
    meta: add_role1rGOgsOd7sMeta || {},
    alias: add_role1rGOgsOd7sMeta?.alias || [],
    redirect: add_role1rGOgsOd7sMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/add_role.vue").then(m => m.default || m)
  },
  {
    name: edit2D4CWW5dzWMeta?.name ?? "users-id-edit",
    path: edit2D4CWW5dzWMeta?.path ?? "/users/:id()/edit",
    meta: edit2D4CWW5dzWMeta || {},
    alias: edit2D4CWW5dzWMeta?.alias || [],
    redirect: edit2D4CWW5dzWMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfosjwbxWtaMeta?.name ?? "users-id",
    path: indexfosjwbxWtaMeta?.path ?? "/users/:id()",
    meta: indexfosjwbxWtaMeta || {},
    alias: indexfosjwbxWtaMeta?.alias || [],
    redirect: indexfosjwbxWtaMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: adds9wtR2FJuZMeta?.name ?? "users-add",
    path: adds9wtR2FJuZMeta?.path ?? "/users/add",
    meta: adds9wtR2FJuZMeta || {},
    alias: adds9wtR2FJuZMeta?.alias || [],
    redirect: adds9wtR2FJuZMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/add.vue").then(m => m.default || m)
  },
  {
    name: indexdlFaUjo5BDMeta?.name ?? "users",
    path: indexdlFaUjo5BDMeta?.path ?? "/users",
    meta: indexdlFaUjo5BDMeta || {},
    alias: indexdlFaUjo5BDMeta?.alias || [],
    redirect: indexdlFaUjo5BDMeta?.redirect,
    component: () => import("/vercel/path0/.app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: edito5crPmYslWMeta?.name ?? "vacancies-id-edit",
    path: edito5crPmYslWMeta?.path ?? "/vacancies/:id()/edit",
    meta: edito5crPmYslWMeta || {},
    alias: edito5crPmYslWMeta?.alias || [],
    redirect: edito5crPmYslWMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexQASgYGPynxMeta?.name ?? "vacancies-id",
    path: indexQASgYGPynxMeta?.path ?? "/vacancies/:id()",
    meta: indexQASgYGPynxMeta || {},
    alias: indexQASgYGPynxMeta?.alias || [],
    redirect: indexQASgYGPynxMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addqQCfItJQmNMeta?.name ?? "vacancies-add",
    path: addqQCfItJQmNMeta?.path ?? "/vacancies/add",
    meta: addqQCfItJQmNMeta || {},
    alias: addqQCfItJQmNMeta?.alias || [],
    redirect: addqQCfItJQmNMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/add.vue").then(m => m.default || m)
  },
  {
    name: indexWZnTPYQ2NQMeta?.name ?? "vacancies",
    path: indexWZnTPYQ2NQMeta?.path ?? "/vacancies",
    meta: indexWZnTPYQ2NQMeta || {},
    alias: indexWZnTPYQ2NQMeta?.alias || [],
    redirect: indexWZnTPYQ2NQMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/vacancies/index.vue").then(m => m.default || m)
  },
  {
    name: indexHq2dcm1jMAMeta?.name ?? "workflows-id-phase_id",
    path: indexHq2dcm1jMAMeta?.path ?? "/workflows/:id()/:phase_id()",
    meta: indexHq2dcm1jMAMeta || {},
    alias: indexHq2dcm1jMAMeta?.alias || [],
    redirect: indexHq2dcm1jMAMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/workflows/[id]/[phase_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexNXogjRzmdTMeta?.name ?? "workflows-id",
    path: indexNXogjRzmdTMeta?.path ?? "/workflows/:id()",
    meta: indexNXogjRzmdTMeta || {},
    alias: indexNXogjRzmdTMeta?.alias || [],
    redirect: indexNXogjRzmdTMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/workflows/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAr3FAPgfCeMeta?.name ?? "workflows",
    path: indexAr3FAPgfCeMeta?.path ?? "/workflows",
    meta: indexAr3FAPgfCeMeta || {},
    alias: indexAr3FAPgfCeMeta?.alias || [],
    redirect: indexAr3FAPgfCeMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/workflows/index.vue").then(m => m.default || m)
  },
  {
    name: zelfgevondenVLq5PsPnEwMeta?.name ?? "zelfgevonden",
    path: zelfgevondenVLq5PsPnEwMeta?.path ?? "/zelfgevonden",
    meta: zelfgevondenVLq5PsPnEwMeta || {},
    alias: zelfgevondenVLq5PsPnEwMeta?.alias || [],
    redirect: zelfgevondenVLq5PsPnEwMeta?.redirect,
    component: () => import("/vercel/path0/stagedoos/pages/zelfgevonden.vue").then(m => m.default || m)
  }
]