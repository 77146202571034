import revive_payload_client_i4dQIfFAvm from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0lWGISLhJx from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i1IlP7vYma from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2XIr6EO1VO from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_zldJ08UCOl from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_H7Ae9WnsOx from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@3.4.26_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/stagedoos/.nuxt/components.plugin.mjs";
import prefetch_client_0BG7MmHkdg from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_CNNVVjQLZp from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_I1G6Op2HJN from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_rollup@4.17.2_vue@3.4.26_typescript@5.4.5_/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_client_3BF5g1cmLk from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.6_patch_hash=zfnymi2b2itc6bxqlfbjljgqim_rollup@4.17.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_Spb8bPNqoC from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.26_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_rpRLVSFz6X from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_ps2okaudbyuo4j2qwtabpjkawa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_8JiZZVnq1T from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_rollup@4.17.2_typescript@5.4.5_vue@_2vtrgj43t5cguofmhzhqc2x7ry/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import default_locale_U9KBtXyBIy from "/vercel/path0/.app/plugins/default-locale.ts";
import sentry_ZPtFjM4jXp from "/vercel/path0/.app/plugins/sentry.ts";
import yup_1KuivxImeD from "/vercel/path0/.app/plugins/yup.ts";
import vue3_signature_nftuGzYaMt from "/vercel/path0/stagedoos/plugins/vue3-signature.ts";
export default [
  revive_payload_client_i4dQIfFAvm,
  unhead_0lWGISLhJx,
  router_i1IlP7vYma,
  payload_client_2XIr6EO1VO,
  check_outdated_build_client_zldJ08UCOl,
  plugin_vue3_H7Ae9WnsOx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0BG7MmHkdg,
  plugin_client_CNNVVjQLZp,
  plugin_I1G6Op2HJN,
  plugin_client_3BF5g1cmLk,
  i18n_Spb8bPNqoC,
  chunk_reload_client_rpRLVSFz6X,
  plugin_8JiZZVnq1T,
  directives_yBPzJQtdia,
  default_locale_U9KBtXyBIy,
  sentry_ZPtFjM4jXp,
  yup_1KuivxImeD,
  vue3_signature_nftuGzYaMt
]